import React, { ChangeEvent, useRef } from 'react';
import styles from './file-upload.scss';
import { Button } from '../button/button';

interface IProps {
    disabled?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FileUpload = (props: IProps) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(e);
        }
    };

    const fileUploadClasses = [styles.fileUpload];
    const labelClasses = [styles.label];
    if (props.disabled) {
        fileUploadClasses.push(styles.disabled);
        labelClasses.push(styles.disabled);
    }

    return (
        <div className={fileUploadClasses.join(' ')}>
            <span className={labelClasses.join(' ')}>
                Drag and drop the files here &nbsp; OR &nbsp;
            </span>
            <Button
                color="accent"
                onClick={handleClick}
                disabled={props.disabled}
            >
                Browse
            </Button>
            {/* TODO */}
            <input
                ref={hiddenFileInput}
                type="file"
                className={styles.hidden}
                onChange={handleChange}
                multiple
            ></input>
        </div>
    );
};
