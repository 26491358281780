import React from 'react';
// import { Colors } from '../../util/colors';
import styles from './textarea.scss';

interface IProps {
    display?: 'inline' | 'block';
}

export const Textarea: React.FC<
    IProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({ ...props }) => {
    return <textarea className={getClasses(props)} {...props}></textarea>;
};

const getClasses = (props: IProps) => {
    return [styles.textArea, getDisplayClass(props.display)]
        .filter((item) => !!item)
        .join(' ');
};

const getDisplayClass = (display?: 'inline' | 'block') => {
    switch (display) {
        case 'inline':
            return styles.displayInline;
        case 'block':
            return styles.displayBlock;
        default:
            return '';
    }
};
