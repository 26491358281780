import React from 'react';
import { Colors } from '../../util/colors';
import styles from './button.scss';

interface IProps {
    color?: Colors;
    size?: 'medium' | 'large';
    className?: string;
}

export const Button: React.FC<
    IProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ ...props }) => {
    const classes = getClasses(props);
    delete props.className;
    return <button className={classes} {...props}></button>;
};

const getClasses = (props: IProps) => {
    return [
        props.className,
        styles.button,
        getColorClass(props.color),
        getSizeClass(props.size)
    ]
        .filter((item) => !!item)
        .join(' ');
};

const getColorClass = (color?: Colors) => {
    switch (color) {
        case Colors.Primary:
            return styles.colorPrimary;
        case Colors.Accent:
            return styles.colorAccent;
        case Colors.Success:
            return styles.colorSuccess;
        case Colors.Warning:
            return styles.colorWarning;
        default:
            return '';
    }
};

const getSizeClass = (size?: 'medium' | 'large') => {
    switch (size) {
        case 'medium':
            return styles.sizeMedium;
        case 'large':
            return styles.sizeLarge;
        default:
            return '';
    }
};
