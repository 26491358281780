import React from 'react';
import styles from './banner.scss';

interface IProps {}

export const Banner: React.FC<
    IProps & React.HTMLAttributes<HTMLDivElement>
> = ({ ...props }) => {
    const classes = [styles.banner, props.className].join(' ');
    return <div {...props} className={classes}></div>;
};
