import { Button, FileUpload, Textarea } from 'dir-ui';
import React, { useState } from 'react';
import { httpService } from '../http-service';
import { UploadIndicator } from '../upload-indicator/upload-indicator';
import styles from './upload-form.module.scss';

interface IFileToUpload {
    done: boolean;
    progress: number;
    file: File;
}

interface IProps {
    onChangeScreen?: (screen: 'login' | 'upload' | 'finished') => void;
}

export const UploadForm = (props: IProps) => {
    const [primaryFiles, setPrimaryFiles] = useState<Array<IFileToUpload>>([]);
    const [secondaryFiles, setSecondaryFiles] = useState<Array<IFileToUpload>>([]);
    const [notes, setNotes] = useState<string | null>();
    const [isError, setIsError] = useState<boolean>(false);
    const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);

    const isFormValid = () => {
        return (
            !submitInProgress &&
            (isError || (primaryFiles.every((file) => file.done) && secondaryFiles.every((file) => file.done)))
        );
    };

    const setFilesToUpload = (filesType: 'primaryFiles' | 'secondaryFiles', filesToUpload: Array<IFileToUpload>) => {
        if (filesType === 'primaryFiles') {
            setPrimaryFiles(filesToUpload);
        } else {
            setSecondaryFiles(filesToUpload);
        }
    };

    const handleFilesChange = (filesType: 'primaryFiles' | 'secondaryFiles', files: FileList | null) => {
        setIsError(false);
        const token = sessionStorage.getItem('token');
        const loginTimestamp = sessionStorage.getItem('login-timestamp');
        if (!token || !loginTimestamp || !files) {
            return;
        }
        const filesToUpload: Array<IFileToUpload> = [];
        if (filesType === 'primaryFiles') {
            primaryFiles.forEach((fileToUpload) => {
                filesToUpload.push(fileToUpload);
            });
        } else {
            secondaryFiles.forEach((fileToUpload) => {
                filesToUpload.push(fileToUpload);
            });
        }

        for (let i = 0; i < files!.length; i++) {
            const fileToUpload: IFileToUpload = { done: false, progress: 0, file: files[i] };

            let formData = new FormData();
            formData.append(filesType, fileToUpload.file);
            httpService
                .post('/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: token,
                        'login-timestamp': loginTimestamp,
                    },
                    onUploadProgress: (data) => {
                        fileToUpload.progress = Math.round((data.loaded * 99) / data.total);
                        const filesInProgress: Array<IFileToUpload> = [];
                        filesToUpload.forEach((fileInProgress) => {
                            filesInProgress.push(fileInProgress);
                        });
                        setFilesToUpload(filesType, filesInProgress);
                    },
                })
                .catch(() => {
                    setIsError(true);
                })
                .finally(() => {
                    fileToUpload.progress = 100;
                    fileToUpload.done = true;
                    const filesInProgress: Array<IFileToUpload> = [];
                    filesToUpload.forEach((fileInProgress) => {
                        filesInProgress.push(fileInProgress);
                    });
                    setFilesToUpload(filesType, filesInProgress);
                });
            filesToUpload.push(fileToUpload);
        }
        setFilesToUpload(filesType, filesToUpload);
    };

    const submitHandler = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setSubmitInProgress(true);
        const token = sessionStorage.getItem('token');
        const loginTimestamp = sessionStorage.getItem('login-timestamp');
        if (!token || !loginTimestamp) {
            return;
        }
        httpService
            .post(
                '/submit',
                { notes },
                {
                    headers: {
                        Authorization: token,
                        'login-timestamp': loginTimestamp,
                    },
                }
            )
            .then(
                (res) => {
                    if (res.status === 200) {
                        if (props.onChangeScreen) {
                            props.onChangeScreen('finished');
                        }
                    } else {
                        setIsError(true);
                    }
                },
                () => {
                    setIsError(true);
                }
            )
            .finally(() => {
                setSubmitInProgress(false);
            });
    };

    const primaryFileIndicators: Array<JSX.Element> = [];
    primaryFiles.forEach((fileToUpload, i) => {
        primaryFileIndicators.push(
            <UploadIndicator
                key={i}
                fileName={fileToUpload.file.name}
                fileSize={getHumanFriendlySize(fileToUpload.file.size)}
                progress={fileToUpload.progress}></UploadIndicator>
        );
    });

    const secondaryFileIndicators: Array<JSX.Element> = [];
    secondaryFiles.forEach((fileToUpload, i) => {
        secondaryFileIndicators.push(
            <UploadIndicator
                key={i}
                fileName={fileToUpload.file.name}
                fileSize={getHumanFriendlySize(fileToUpload.file.size)}
                progress={fileToUpload.progress}></UploadIndicator>
        );
    });

    const loader = () => {
        if (!isFormValid()) {
            return <img className={styles.loader} src="./loading.svg" alt="loading" />;
        }
    };
    const errorMessage = () => {
        if (isError) {
            return (
                <p className={styles.center}>
                    Unexpected error.{' '}
                    <Button
                        color="warning"
                        onClick={() => {
                            if (props.onChangeScreen) {
                                props.onChangeScreen('login');
                            }
                        }}>
                        Try again
                    </Button>
                </p>
            );
        }
    };
    const submit = () => {
        if (!isError) {
            return (
                <Button className={styles.submit} size="large" type="submit" disabled={!isFormValid()}>
                    Submit
                </Button>
            );
        }
    };

    return (
        <form
            action={process.env.REACT_APP_API_URL + '/upload'}
            method="post"
            encType="multipart/form-data"
            onSubmit={submitHandler}>
            <h4>Primary images</h4>
            <FileUpload
                onChange={(e) => {
                    handleFilesChange('primaryFiles', e.target.files);
                }}></FileUpload>
            {primaryFileIndicators}
            <h4>Secondary images</h4>
            <FileUpload
                onChange={(e) => {
                    handleFilesChange('secondaryFiles', e.target.files);
                }}></FileUpload>
            {secondaryFileIndicators}
            <h4>Notes</h4>
            <Textarea
                display="block"
                rows={8}
                onChange={(e) => {
                    setNotes(e.currentTarget.value);
                }}></Textarea>
            <br />
            {submit()}
            {loader()}
            {errorMessage()}
        </form>
    );
};

function getHumanFriendlySize(sizeInBytes: number): string {
    let size = sizeInBytes;
    let unit = 'B';
    if (size > 1000) {
        size = parseFloat((size / 1000).toFixed(2));
        unit = 'KB';
    }
    if (size > 1000) {
        size = parseFloat((size / 1000).toFixed(2));
        unit = 'MB';
    }
    if (size > 1000) {
        size = parseFloat((size / 1000).toFixed(2));
        unit = 'GB';
    }
    if (size > 1000) {
        size = parseFloat((size / 1000).toFixed(2));
        unit = 'TB';
    }
    return size.toString() + unit;
}
