import React from 'react';
// import { Colors } from '../../util/colors';
import styles from './input.scss';

interface IProps {}

export const Input: React.FC<
    IProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({ ...props }) => {
    const classes = [styles.input, props.className].join(' ');
    return <input className={classes} {...props}></input>;
};
