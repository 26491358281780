import React, { useState } from 'react';
import { Banner, Button, Input } from 'dir-ui';
import styles from './login-form.module.scss';
import { httpService } from '../http-service';

interface IProps {
    onChangeScreen?: (screen: 'login' | 'upload' | 'finished') => void;
}

export const LoginForm = (props: IProps) => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    const handleClick = () => {
        setIsError(false);
        httpService.post('/login', { username, password }).then(
            (res) => {
                if (res.status === 200 && !!res.data && !!res.data.token) {
                    sessionStorage.setItem('token', res.data.token);
                    sessionStorage.setItem('login-timestamp', new Date().toISOString().replace(/:|\./g, '-'));
                    if (props.onChangeScreen) {
                        props.onChangeScreen('upload');
                    }
                } else {
                    setIsError(true);
                }
            },
            () => {
                setIsError(true);
            }
        );
    };

    const isFormValid = () => {
        return !!username && !!password;
    };

    const errorMessage = () => {
        if (isError) {
            return <p>Could not log in.</p>;
        }
    };

    return (
        <>
            <Banner className={styles.banner}>
                <img src="logo-csl.png" alt="Cosylab" className={styles.logo} />
            </Banner>
            <div className={styles.modal}>
                <div>
                    <h2 className={styles.header}>Login</h2>
                    <div className={styles.loginInput}>
                        <Input placeholder="Username" onChange={(e) => setUsername(e.currentTarget.value)}></Input>
                    </div>
                    <div className={styles.loginInput}>
                        <Input
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.currentTarget.value)}></Input>
                    </div>
                    {errorMessage()}
                    <Button type="submit" onClick={handleClick} disabled={!isFormValid()}>
                        Log in
                    </Button>
                </div>
            </div>
        </>
    );
};
