import React from 'react';
import styles from './progress-bar.scss';

interface IProps {
    progress?: number;
}

export const ProgressBar = (props: IProps) => {
    let progress = 0;
    if (!!props.progress && props.progress > 0 && props.progress <= 100) {
        progress = props.progress;
    }

    return (
        <div className={styles.progressBar}>
            <div
                className={styles.indicator}
                style={{ width: `${progress}%` }}
            ></div>
        </div>
    );
};
