import React, { useState } from 'react';
import { StyleBaseline, Toolbar } from 'dir-ui';
import styles from './app.module.scss';
import { LoginForm } from './login-form/login-form';
import { UploadForm } from './upload-form/upload-form';
import { Finished } from './finished/finished';

function App() {
    const [screen, setScreen] = useState<'login' | 'upload' | 'finished'>('login');

    const drawScreen = () => {
        switch (screen) {
            case 'finished':
                return (
                    <>
                        <Toolbar>
                            <img src="logo-csl.png" alt="Cosylab" className={styles.logo} />
                        </Toolbar>
                        <div className={styles.wrapper}>
                            <Finished onChangeScreen={setScreen}></Finished>
                        </div>
                    </>
                );
            case 'upload':
                return (
                    <>
                        <Toolbar>
                            <img src="logo-csl.png" alt="Cosylab" className={styles.logo} />
                        </Toolbar>
                        <div className={styles.wrapper}>
                            <UploadForm onChangeScreen={setScreen}></UploadForm>
                        </div>
                    </>
                );
            default:
                return <LoginForm onChangeScreen={setScreen}></LoginForm>;
        }
    };

    return (
        <>
            <StyleBaseline></StyleBaseline>
            {drawScreen()}
        </>
    );
}

export default App;
