import React from 'react';
import styles from './toolbar.scss';

interface IProps {}

export const Toolbar: React.FC<
    IProps & React.HTMLAttributes<HTMLDivElement>
> = ({ ...props }) => {
    return <div {...props} className={styles.toolbar}></div>;
};
