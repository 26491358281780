import React from 'react';
import { Button } from 'dir-ui';
import styles from './finished.module.scss';

interface IProps {
    onChangeScreen?: (screen: 'login' | 'upload' | 'finished') => void;
}

export const Finished = (props: IProps) => {
    const handleClick = () => {
        if (props.onChangeScreen) {
            props.onChangeScreen('login');
        }
    };

    return (
        <>
            <h1 className={styles.thankYou}>
                Thank you for uploading the images, we will get back to you with the results.
            </h1>
            <p>
                If you want to upload additional files return to the
                <Button onClick={handleClick}>Login</Button>.
            </p>
        </>
    );
};
