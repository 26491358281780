import { ProgressBar } from 'dir-ui';
import React from 'react';
import './upload-indicator.scss';

interface IProps {
    progress?: number;
    fileName?: string;
    fileSize?: string;
}

export const UploadIndicator = (props: IProps) => {
    return (
        <div className="indicator">
            <img src="icon-file.svg" alt=""></img>
            <div>
                <div className="info">
                    <span>
                        <small className="filename">
                            {props.fileName} <span className="light">({props.fileSize})</span>
                        </small>
                    </span>
                    <span>
                        <small className="progress light">{props.progress}%</small>
                    </span>
                </div>
                <ProgressBar progress={props.progress}></ProgressBar>
            </div>
        </div>
    );
};
